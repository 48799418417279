export const resizableListener = (
  horizontalPositionContainer: number,
  callback: (horizontalPosition: number,
 ) => void) => {
    const setPosition = (e: MouseEvent) => callback(e.x - horizontalPositionContainer);

    document.addEventListener('mousemove', setPosition, false);
    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', setPosition, false);
    }, false);
};
