
























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {
  calculateMainWidth,
  horizontalPositionElement,
  widthElement,
} from '@/layouts/back-office/elements/resize-container/DeterminePositionHelpers';
import {resizableListener} from '@/layouts/back-office/elements/resize-container/ResizableListener';

@Component({
  components: {},
})
export default class ResizableContainer extends Vue {
  @Prop({type: Boolean, required: false, default: true}) private displaySidebar!: boolean;
  @Prop({type: String, required: false, default: '15%'}) private widthSidebar!: string;
  @Prop({type: Boolean, required: false, default: false}) private fixedSidebar!: boolean;

  private container!: HTMLDivElement;
  private main!: HTMLDivElement;

  private listener(): void {
    resizableListener(
      horizontalPositionElement(this.container),
      (horizontalPosition) => this.setFlexBasis(horizontalPosition),
    );
  }

  private mounted() {
    this.$nextTick(function() {
      this.setDefaultSettings();
    });
  }

  private setSelectors(): void {
    this.container = this.fetchElement('.container-wrapper');
    this.main = this.fetchElement('.main-content');
  }

  private setDefaultFlexBasis(widthSidebar: string): void {
    const resizeBarWidth = this.displaySidebar ? widthElement(this.fetchElement('.resizer')) : 0;
    const mainWidth = calculateMainWidth(this.container.clientWidth, widthSidebar, resizeBarWidth);
    this.setFlexBasis(mainWidth);
  }

  private setFlexBasis(width: number): void {
    this.main.style.flexBasis = `${width}px`;
  }

  private fetchElement(selector: string): HTMLDivElement {
    return this.$el.querySelector(selector) as HTMLDivElement;
  }

  @Watch('displaySidebar')
  private setDefaultSettings(): void {
    this.setSelectors();

    const widthSidebar = this.displaySidebar ? this.widthSidebar : '0';
    this.$nextTick(() => this.setDefaultFlexBasis(widthSidebar));
  }
}
