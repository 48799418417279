








import {Vue} from 'vue-property-decorator';


export default class LoadingEllipsis extends Vue {

}
