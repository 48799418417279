export const calculateMainWidth = (
  containerWidth: number,
  sidebarWidth: string,
  resizableBarWidth: number,
): number => {
  const numberWidth: number = parseInt(sidebarWidth, 0);

  if (sidebarWidth.includes('%')) {
    return calcMainWidthBySidebarPercentage(containerWidth, numberWidth, resizableBarWidth);
  }
  return containerWidth - numberWidth;
};

const calcMainWidthBySidebarPercentage = (
  containerWidth: number,
  sidebarPercentageWidth: number,
  resizableBarWidth: number,
): number => {
  const sidebarWidth = (containerWidth / 100) * sidebarPercentageWidth;
  return containerWidth - resizableBarWidth - sidebarWidth;
};

export const boundingClientRect = (element: HTMLDivElement) => {
  return element.getBoundingClientRect();
};

export const horizontalPositionElement = (element: HTMLDivElement) => {
  const clientRect = boundingClientRect(element);
  return clientRect.x;
};

export const widthElement = (element: HTMLDivElement) => {
  const clientRect = boundingClientRect(element);
  return clientRect.width;
};
