




































import {Component, Prop, Vue} from 'vue-property-decorator';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';

@Component({
  components: {
    Spinner,
  },
})
export default class Alert extends Vue {

  @Prop({type: String, required: true, default: 'info'}) private severity!: 'info' | 'warning'  | 'success' | 'error';
  @Prop({type: String, required: true, default: 'regular'}) private variant!: 'regular' | 'contained' | 'outlined';
  @Prop({type: Boolean, required: false, default: false}) private canClose!: boolean;
  @Prop({type: Boolean, required: false, default: false}) private isLoading!: boolean;
  @Prop({type: Boolean, required: false, default: false}) private noIcon!: boolean;


  private get alertClasses() {
    return {
      'variant-contained': this.variant === 'contained',
      'variant-outlined': this.variant === 'outlined',
      'variant-regular': this.variant === 'regular',
      'severity-info': this.severity === 'info',
      'severity-warning': this.severity === 'warning',
      'severity-success': this.severity === 'success',
      'severity-error': this.severity === 'error',
    };
  }

  private get spinnerColor(): string {
    if (this.variant === 'contained') {
      return '#fff';
    }
    if (this.severity === 'info') {
      return '#3db89a';
    }
    if (this.severity === 'warning') {
      return '#f4b850';
    }
    if (this.severity === 'success') {
      return '#8abf59';
    }
    if (this.severity === 'error') {
      return '#a72532';
    }
    return '#000';
  }

  private handleClose() {
    this.$emit('closeAlert');
  }

}
